<template>
  <map-basic
    ref="basicmap"
    :mapZoom="mapZoom"
    :mapCenter="mapCenter"
    :backgroundLayers="backgroundLayers"
    :foregroundLayers="foregroundLayers"
    :overlayLayers="overlayLayers"
    :max-zoom="16"
    style="height: 100%; width: 100%; z-index: 0;"
    :class="interaction ? 'draggable' : 'hideControls'"
  >
    <template slot-scope="{mapObject}">
    <div v-if="interaction" class="eox-logo-container">
      <a href="https://eox.at" target="_blank">
        <img class="eox-logo" src="img/EOX_Logo_white.svg" alt="Check out the EOX website" />
      </a>
      <a href="https://cloudless.eox.at/" target="_blank">
        <img class="eox-cloudless-logo" src="img/eoxcloudless_logo_white.svg"
        alt="Check out the EOxCloudless website" />
      </a>
    </div>
      <div
        v-if="interaction"
        :class="isTouch ? 'ol-touch' : ''"
      >
        <map-layer-swipe
          v-if="mapObject"
          :mapObject="mapObject"
          ref="swipe"
          reverseDirection
          :swipeLayer="foregroundLayers[0]"
          :originalLayer="backgroundLayers[0]"
        />
        <div class="ol-control map-buttons top-left">
          <map-button
            title="Show borders and labels"
            @click.native="toggleOverlay()"
          >
            <i class="material-icons">layers</i>
          </map-button>
          <map-button
            title="Compare changes"
            @click.native="toggleCompare()"
            attention
          >
            <i class="material-icons">access_time</i>
          </map-button>
          <map-button
            title="Download image of selected location or entire dataset"
            @click.native="downloadModal = true"
          >
            <i class="material-icons">file_download</i>
          </map-button>
        </div>
        <!-- <div class="ol-control map-buttons bottom-left"> -->
          <map-tour
            ref="tour"
            @active="(active) => tourActive = active"
            @setLocation="flyTo"
            @stopTour="stopFlying"
          />
        <!-- </div> -->
        <div class="ol-control map-buttons top-right">
          <map-button
            title="Close interaction"
            @click.native="close()"
            text
          >
            <i class="material-icons">clear</i> <span>Close</span>
          </map-button>
        </div>
        <template
          v-if="mapObject && $refs.swipe && $refs.swipe.swipeActive"
        >
          <div
            class="select-year select-left select-up"
            :style="`clip-path: inset(0px ${$refs.swipe.clipLeft}px 0px 0px`"
          >
            <i
              v-if="layers.indexOf(foregroundLayers[0]) > 0"
              class="material-icons"
              @click="changeForegroundLayer(layers.indexOf(foregroundLayers[0]) - 1)"
            >keyboard_arrow_up</i>
          </div>
          <div
            class="select-year select-left select-down"
            :style="`clip-path: inset(0px ${$refs.swipe.clipLeft}px 0px 0px`"
          >
            <i
              v-if="layers.indexOf(foregroundLayers[0]) < layers.length -1"
              class="material-icons"
              @click="changeForegroundLayer(layers.indexOf(foregroundLayers[0]) + 1)"
            >keyboard_arrow_down</i>
          </div>
          <div
            class="select-year select-right select-up"
            :style="`clip-path: inset(0px 0px 0px ${$refs.swipe.clipRight}px`"
          >
            <i
              v-if="layers.indexOf(backgroundLayers[0]) > 0"
              class="material-icons"
              @click="changeBackgroundLayer(layers.indexOf(backgroundLayers[0]) - 1)"
            >keyboard_arrow_up</i>
          </div>
          <div
            class="select-year select-right select-down"
            :style="`clip-path: inset(0px 0px 0px ${$refs.swipe.clipRight}px`"
          >
            <i
              v-if="layers.indexOf(backgroundLayers[0]) < layers.length -1"
              class="material-icons"
              @click="changeBackgroundLayer(layers.indexOf(backgroundLayers[0]) + 1)"
            >keyboard_arrow_down</i>
          </div>
        </template>
      </div>
      <div
        v-if="interaction && !tourActive"
        class="social-int"
      >
        <ul class="social-network social-circle">
          <li v-for="(sm, key) in socialMedia" :key="key">
            <a
              :href="sm.url"
              target="blank"
              :class="`${sm.iconClass} a-ico`"
              :title="sm.title"
              @click="socialClick(sm.icon.replace('fa-', ''))">
              <i :class="`fa ${sm.icon}`"></i>
            </a>
          </li>
        </ul>
      </div>
      <download-modal v-if="downloadModal && interaction" @close="downloadModal = false" />
    </template>
  </map-basic>
</template>

<script>
import MapBasic from '@eox/map-basic';
import MapLayerSwipe from '@eox/map-layer-swipe';

import MapButton from './MapButton.vue';
import MapTour from './MapTour.vue';
import DownloadModal from './DownloadModal.vue';

import closeMixin from '../mixins/close';

const layerDefaults = {
  type: 'tile',
  dataProvider: 'WMTScapabilites',
  capabilitiesUrl: `${process.env.BASE_URL}WMTSCapabilities.xml`,
  matrixSet: 'GoogleMapsCompatible',
};

export default {
  mixins: [closeMixin],
  props: {
    interaction: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    MapBasic,
    MapLayerSwipe,
    MapButton,
    MapTour,
    DownloadModal,
  },
  computed: {
    isTouch() {
      // check if ol-touch class exists
      return document.querySelector('.ol-touch') !== null;
    },
  },
  data: () => ({
    mapZoom: 4,
    mapCenter: [0, 4000000],
    layers: [
      {
        ...layerDefaults,
        visible: true,
        name: 's2cloudless-2024_3857',
        title: '2024',
      },
      {
        ...layerDefaults,
        visible: false,
        name: 's2cloudless-2023_3857',
        title: '2023',
      },
      {
        ...layerDefaults,
        visible: false,
        name: 's2cloudless-2022_3857',
        title: '2022',
      },
      {
        ...layerDefaults,
        visible: false,
        name: 's2cloudless-2021_3857',
        title: '2021',
      },
      {
        ...layerDefaults,
        visible: false,
        name: 's2cloudless-2020_3857',
        title: '2020',
      },
      {
        ...layerDefaults,
        visible: false,
        name: 's2cloudless-2019_3857',
        title: '2019',
      },
      {
        ...layerDefaults,
        visible: false,
        name: 's2cloudless-2018_3857',
        title: '2018',
      },
      {
        ...layerDefaults,
        visible: false,
        name: 's2cloudless_3857',
        title: '2016',
      },
    ],
    backgroundLayers: [],
    foregroundLayers: [],
    overlayLayers: [
      {
        ...layerDefaults,
        visible: false,
        name: 'overlay_base_bright_3857',
        // title: 'Sentinel-2 cloudless 2019',
      },
    ],
    socialMedia: [
      {
        url: 'https://www.facebook.com/eox.at',
        iconClass: 'icoFacebook',
        title: 'EOX on Facebook',
        icon: 'fa-facebook',
      },
      {
        url: 'https://twitter.com/eox_a',
        iconClass: 'icoTwitter',
        title: 'EOX on Twitter',
        icon: 'fa-twitter',
      },
      {
        url: 'https://www.linkedin.com/company/eox-it-services-gmbh',
        iconClass: 'icoLinkedin',
        title: 'EOX on Linkedin',
        icon: 'fa-linkedin',
      },
    ],
    downloadModal: false,
    tourActive: false,
  }),
  mounted() {
    this.$refs.basicmap.$refs.map.$on('created', this.mapCreated);
    this.backgroundLayers.push(this.layers[0]);
    this.foregroundLayers.push(this.layers[1]);

    this.$refs.basicmap.$watch('center', (newCenter) => (this.mapCenter = newCenter));
    this.$refs.basicmap.$watch('zoom', (newZoom) => this.mapZoom = newZoom);
  },
  methods: {
    mapCreated() {
      // initially disable interactions
      this.setInteractions(false);
    },
    setInteractions(active) {
      this.$refs.basicmap
        .$refs.map
        .$map.getInteractions()
        .forEach((x) => x.setActive(active));
    },
    close() {
      if (this.interaction) {
        this.$emit('closeInteraction');
      }
    },
    changeBackgroundLayer(newIndex) {
      this.backgroundLayers = [];
      this.backgroundLayers.push(this.layers[newIndex]);
      this.backgroundLayers[0].visible = true;
    },
    changeForegroundLayer(newIndex) {
      this.foregroundLayers = [];
      this.foregroundLayers.push(this.layers[newIndex]);
      this.foregroundLayers[0].visible = true;
    },
    toggleCompare() {
      const currentlyActive = this.foregroundLayers[0].visible;
      if (currentlyActive) {
        this.foregroundLayers[0].visible = false;
        this.$refs.swipe.enableSwipe(false);
      } else {
        this.foregroundLayers[0].visible = true;
        this.$refs.swipe.enableSwipe(true);
      }
      this.trackEvent('map', `layer swipe ${currentlyActive ? 'hide' : 'show'}`);
    },
    toggleOverlay() {
      const currentState = this.overlayLayers[0].visible;
      this.overlayLayers[0].visible = !currentState;
      this.trackEvent('map', `overlay ${currentState ? 'hide' : 'show'}`);
    },
    flyTo(poi) {
      const view = this.$refs.basicmap
        .$refs.map.$children[0].$view;
      if (view.getAnimating()) {
        this.stopFlying();
      }
      const duration = 7000;
      let parts = 2;
      let called = false;

      const callback = (complete) => {
        parts -= 1;
        if (called) {
          return;
        }
        if (parts === 0 || !complete) {
          called = true;
        }
      };
      view.animate(
        {
          center: poi.center,
          duration,
        },
        callback,
      );
      view.animate(
        {
          zoom: poi.zoom - 7,
          duration: duration / 2,
        },
        {
          zoom: poi.zoom,
          duration: duration / 2,
        },
        callback,
      );
    },
    stopFlying() {
      const view = this.$refs.basicmap
        .$refs.map.$children[0];
      view.$view.cancelAnimations();
    },
    socialClick(social) {
      this.trackEvent('button click', `map ${social}`);
    },
  },
  watch: {
    interaction(active) {
      this.setInteractions(active);
      if (!active) {
        this.foregroundLayers[0].visible = false;
        this.$refs.swipe.enableSwipe(false);
      }
      this.trackEvent('map', `${active ? 'explore' : 'close'}`);
    },
  },
};
</script>

<style lang="scss">
.map  {
  .vl-map {
    position: relative;
    .map-buttons {
      position: absolute;
    }
    .top-left {
      top: 7.5em;
      left: .5em;
    }
    .top-right {
      top: .5em;
      right: .5em;
    }
    .bottom-left {
      bottom: .5em;
      left: .5em;
    }
  }
  .vl-map .ol-touch {
    .top-left {
      top: 6em;
    }
  }
  .ol-overlaycontainer-stopevent {
    z-index: 15 !important;
  }
  .vl-map.hideControls {
    .ol-overlaycontainer, .ol-overlaycontainer-stopevent {
      display: none;
    }
  }
  .select-year {
    font-size: 4em;
    z-index: 1;
    display: flex;
    flex-direction: column;
    position: absolute;
    i {
      opacity: .5;
      background: var(--v-primary-base);
      color: white;
      font-size: 1.93em;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }
  .select-left {
    left: 0;
  }
  .select-right {
    right: 0;
  }
  .select-up {
    top: 50%;
    transform: translateY(-130%);
  }
  .select-down {
    top: 50%;
    transform: translateY(30%);
  }
}
.eox-logo-container {
  top: .5em;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 0.3125em 0.625em;
  border-radius: 10px;
  position: absolute;
  z-index: 1;
  opacity: 0.8;

  .eox-logo {
    height: 2em;
    margin-right: 1em;
  }

  .eox-cloudless-logo {
    height: 2.5em;
  }
}
@media only screen and (min-width: 1900px) {
  .eox-logo-container {
  top: unset;
  transform: unset;
  bottom: .5em;
  left: 10em;
  }
}
</style>
